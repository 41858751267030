<template>
  <cui-acl  :roles="['talkmoreB2Csettings']" redirect>
    <div class="kit__utils__heading">
      <h5>
        <span>Talkmore Privat Instillinger</span>
      </h5>
    </div>
      <h5 class="mb-3">
        Produkter
        <a-button style="float: right;" @click="downloadData()">
          Last ned informasjon
        </a-button>
      </h5>
    <a-table bordered :data-source="dataSource" :columns="columns" :pagination="{ pageSize: 50, hideOnSinglePage: true }">
      <template slot="name" slot-scope="text, record" >
        <editable-cell :text="text" @change="onCellChange(record.key, 'name', $event)" />
      </template>
      <template slot="type" slot-scope="text, record">
        <dropdown-cell :text="text" @change="onCellChange(record.key, 'type', $event)" :options="[{value: 'main', text: 'Hoved'}, {value: 'extra', text: 'Mersalg'}]" />
      </template>
      <template slot="family" slot-scope="text, record">
        <dropdown-cell :text="text" @change="onCellChange(record.key, 'family', $event)" :options="[{value: 'true', text: 'Ja'}, {value: 'false', text: 'Nei'}]" />
      </template>
      <template slot="comission" slot-scope="text, record">
        <editable-cell :text="String(text)" @change="onCellChange(record.key, 'comission', $event)" />
      </template>
      <template slot="points" slot-scope="text, record">
        <editable-cell :text="String(text)" @change="onCellChange(record.key, 'points', $event)" />
      </template>
      <template slot="status" slot-scope="text, record">
        <dropdown-cell :text="text" @change="onCellChange(record.key, 'status', $event)" :options="[{value: 'active', text: 'Aktiv'}, {value: 'inactive', text: 'Inaktiv'}]" />
      </template>
      <template slot="sequence" slot-scope="text, record">
        <editable-cell :text="String(text)" @change="onCellChange(record.key, 'sequence', $event)" />
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-popconfirm
          v-if="dataSource.length"
          title="Sure to delete?"
          @confirm="() => onDelete(record.key)"
        >
          <a href="javascript:;">Slett</a>
        </a-popconfirm>
      </template>
    </a-table>
    <a-button class="editable-add-btn mt-3" @click="handleAdd">
      Legg til
    </a-button>
    <!-- <a-divider></a-divider>
    <h5>
      Kampanjer
    </h5>
    <a-table bordered :data-source="campaigns" :columns="campaignColumns">

    </a-table> -->
  </cui-acl>
</template>
<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import { firestore } from '@/services/firebase'
import { mapState } from 'vuex'
import exportFromJSON from 'export-from-json'
import EditableCell from '@/components/custom/visual/editableCell'
import DropdownCell from '@/components/custom/visual/dropdownCell'

function onChange(pagination, filters, sorter) {
  console.log('params', pagination, filters, sorter)
}

export default {
  name: 'TablesAntdFilterSorter',
  computed: {
    ...mapState(['user']),
  },
  components: {
    cuiAcl,
    EditableCell,
    DropdownCell,
  },
  data() {
    return {
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      dataSource: [],
      count: 0,
      columns: [
        {
          title: 'Produktnavn',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Type',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
        },
        {
          title: 'Familie',
          dataIndex: 'family',
          scopedSlots: { customRender: 'family' },
        },
        {
          title: 'Provisjon',
          dataIndex: 'comission',
          scopedSlots: { customRender: 'comission' },
        },
        {
          title: 'Poeng',
          dataIndex: 'points',
          scopedSlots: { customRender: 'points' },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: 'Sekvens',
          dataIndex: 'sequence',
          scopedSlots: { customRender: 'sequence' },
        },
      ],
    }
  },
  methods: {
    onChange,
    onCellChange(key, dataIndex, value) {
      console.log({ key, dataIndex, value })
      const dataSource = [...this.dataSource]
      const target = dataSource.find(item => item.key === key)
      if (target) {
        target[dataIndex] = value
        this.dataSource = dataSource
        // replace comma with dot if comission or points
        if (dataIndex === 'comission' || dataIndex === 'points') {
          target[dataIndex] = target[dataIndex].replace(',', '.')
        }
        firestore.collection('tmb2c-products').doc(target.id)
          .set({
            name: target.name,
            points: Number(target.points),
            sequence: Number(target.sequence),
            status: target.status,
            family: Boolean(target.family === 'true'),
            type: target.type,
            comission: Number(target.comission),
          })
        this.reorder()
        this.$forceUpdate()
      }
    },
    onDelete(key) {
      const dataSource = [...this.dataSource]
      const target = dataSource.find(obj => obj.key === key)
      console.log(target)
      firestore.collection('tmb2c-products').doc(target.id).delete()
      this.dataSource = dataSource.filter(obj => obj.key !== key)
      this.$forceUpdate()
    },
    handleAdd() {
      const { dataSource } = this
      this.count = this.count + 1
      const newData = {
        key: this.count,
        name: `Ny Produkt ${this.count + 1}`,
        type: 'main',
        family: 'false',
        points: 0,
        status: 'inactive',
        comission: 0,
      }
      firestore.collection('tmb2c-products').add(newData)
        .then(res => {
          const fireData = newData
          fireData.id = res.id
          fireData.points = String(fireData.points)
          this.dataSource = [...dataSource, fireData]
          console.log(fireData)
          this.$forceUpdate()
        })
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    downloadData() {
      const data = this.dataSource
      const fileName = 'product data'
      const exportType = exportFromJSON.types.csv
      const delimiter = ';'
      exportFromJSON({ data, fileName, exportType, delimiter })
    },
    reorder() {
      // order all objects by sequence. if there is no sequence, put it at the end
      this.dataSource = this.dataSource.sort((a, b) => {
        if (a.sequence === undefined) {
          return 1
        }
        if (b.sequence === undefined) {
          return -1
        }
        return a.sequence - b.sequence
      })
      // put all objects with attribute status inactive at the end
      this.dataSource = this.dataSource.sort((a, b) => {
        if (a.status === 'inactive') {
          return 1
        }
        if (b.status === 'inactive') {
          return -1
        }
        return 0
      })
    },
  },
  mounted() {
    // create a search bar on top to filter any value for user const res = arr.filter(obj => Object.values(obj).some(val => val.includes(search)));
    firestore.collection('tmb2c-products').orderBy('name', 'asc')
      .get().then(snapshot => {
        this.dataSource = []
        this.count = 0
        snapshot.forEach(doc => {
          this.dataSource.push({
            key: this.count,
            id: String(doc.id),
            name: String(doc.data().name),
            type: (doc.data().type),
            points: String(doc.data().points),
            family: String(doc.data().family),
            status: String(doc.data().status),
            sequence: Number(doc.data().sequence),
            comission: Number(doc.data().comission),
          })
          this.count += 1
        })
        this.reorder()
        if (['underDevelopment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
          this.columns.push({
            title: 'Slett',
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' },
          })
        } else {
          this.dataSource = this.dataSource.filter(obj => obj.status === 'active')
          console.log(this.dataSource)
        }
        return firestore.collection('tmb2c-campaigns').get()
      })
  },
}
</script>
